<template>
  <div class="container-fluid">
    <nav>
      <div class="nav nav-tabs">
        <a
          class="nav-link"
          :class="selectedFeature === 'admin.user' ? 'active' : ''"
          id="nav-home-tab"
          data-toggle="tab"
          href="#nav-home"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
        >
          <router-link :to="{ name: 'admin.user' }">
            User Management
          </router-link>
        </a>
        <a
          class="nav-link"
          :class="selectedFeature === 'admin.file' ? 'active' : ''"
          id="nav-profile-tab"
          data-toggle="tab"
          href="#nav-profile"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="false"
        >
          <router-link :to="{ name: 'admin.file' }">
            File Management
          </router-link>
        </a>
        <a
          class="nav-link"
          :class="selectedFeature === 'admin.cms' ? 'active' : ''"
          id="nav-contact-tab"
          data-toggle="tab"
          href="#nav-contact"
          role="tab"
          aria-controls="nav-contact"
          aria-selected="false"
        >
          <router-link :to="{ name: 'admin.cms' }">
            Web Content Management
          </router-link>
        </a>
        <a
          class="nav-link"
          :class="selectedFeature === 'admin.page' ? 'active' : ''"
          id="nav-contact-tab"
          data-toggle="tab"
          href="#nav-contact"
          role="tab"
          aria-controls="nav-contact"
          aria-selected="false"
        >
          <router-link :to="{ name: 'admin.page' }">
            Homepage Configuration
          </router-link>
        </a>
      </div>
    </nav>

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import axios from "axios";

export default {
  middleware: "auth",
  layout: "admin",

  data: () => {
    return {
      selectedFeature: "admin.cms",
      // cms_sections: [],
      id: null,
    };
  },
  computed: {
    tabs() {
      return [
        {
          icon: "user",
          name: this.$t("profile"),
          route: "admin.profile",
        },
        {
          icon: "lock",
          name: this.$t("password"),
          route: "settings.password",
        },
      ];
    },
  },
  created: function () {
    this.id = this.$route.query.id || -1;
    this.selectFeature(this.$route.name);
    console.log(this.$route.name + " " + this.$route.query.id);
  },
  watch: {
    $route(to, from) {
      this.id = this.$route.query.id || -1;
      this.selectFeature(this.$route.name);
      console.log(this.$route.name + " " + this.$route.query.id);
    },
  },
  methods: {
    selectFeature: function (feature) {
      this.selectedFeature = feature;
      // if (this.selectedFeature === "admin.cms") {
      //   // this.getHomepageSections();
      // }
    },
  },
};
</script>

<style>
.settings-card .card-body {
  padding: 0;
}
</style>
